<template>
    <v-card
        flat
        tile
        :loading="loading"
    >
        <w-simple-form
            :disabled="loading"
            :fields="formFields"
            @submit="onSubmit"
        />
    </v-card>
</template>

<script>
import { required, urlValidator } from '@/utils/validation'
import { uploadUrl } from '@apps/media/api'
import uploadFormMixin from '@apps/media/components/MediaUploadForm/mixins/uploadFormMixin'

export default {
    name: 'UploadLinkForm',
    mixins: [ uploadFormMixin ],
    computed: {
        formFields() {
            return [
                {
                    type: 'v-text-field',
                    name: 'url',
                    props: {
                        label: this.$trans('Link'),
                        disabled: this.loading,
                        outlined: true,
                        dense: true,
                        rules: [
                            required,
                            urlValidator
                        ]
                    }
                }
            ]
        }
    },
    methods: {
        async onSubmit(data) {
            this.loading = true

            try {
                const payload = {
                    ...data
                }

                if(this.parent) {
                    payload.parent = this.parent
                }

                const response = await uploadUrl(payload)

                this.$emit('success', { data: response.data.data })
            } catch (error) {
                this.errors = error.response &&
                error.response.data &&
                error.response.data.errors
                    ? error.response.data.errors
                    : {}

                this.$emit('error', { errors: this.errors })
            } finally {
                this.loading = false
            }
        }
    }
}
</script>

<style lang=scss>

</style>
